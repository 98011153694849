import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Table } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { CreateBot as textLang } from 'staticData/languages';
import AppContext from 'context/Context';

const BacktestTable = ({
  data,
  getTotalForYear,
  selectedYear,
  selectedMonth = null,
  endSimulation
}) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const yearEndSimulation = endSimulation.endYear;
  const monthEndSimulation = endSimulation.endMonth; //months start from 0
  const isMobile = window.innerWidth < 768;
  const [columns, setColumns] = useState([]);

  const dataReformat = Object.keys(data).map(year => ({
    year,
    ...data[year].reduce((acc, value, index) => {
      acc[`month${index + 1}`] = value;
      return acc;
    }, {}),
    total: getTotalForYear(data, year)
  }));

  const selectedData = data[selectedYear];
  const months = isMobile ? textLang.months[lang] : textLang.shortMonths[lang];

  const getCellClass = value => {
    if (value > 0) return 'success';
    if (value < 0) return 'danger';
    return 'dark';
  };

  useEffect(() => {
    const updatedColumns = [
      {
        accessor: 'year',
        Header: textLang.year[lang]
      },
      ...Array.from({ length: 12 }, (_, i) => ({
        accessor: `month${i + 1}`,
        Header: months[i],
        headerProps: {
          className: 'text-end'
        },
        Cell: ({ row, value }) => {
          const year = row.original.year;
          const isBeforeReferenceMonth =
            Number(year) < Number(yearEndSimulation) ||
            (year === yearEndSimulation && i < monthEndSimulation);
          return selectedMonth != null &&
            Number(year) === Number(selectedYear) &&
            Number(selectedMonth) === Number(i) ? (
            <div className={`text-dark text-end fs--1`}>
              <b className={`bg-${getCellClass(selectedData[i])} rounded p-2`}>
                {value}
              </b>
            </div>
          ) : (
            <div className={`text-${getCellClass(value)} text-end fs--1`}>
              {value}
              <span className="text-dark">
                {isBeforeReferenceMonth ? '*' : ''}
              </span>
            </div>
          );
        }
      })),
      {
        accessor: 'total',
        Header: 'Totale',
        headerProps: {
          className: 'text-end'
        },
        Cell: ({ value }) => (
          <div className={`text-${getCellClass(value)} text-end`}>
            <b>{value}%</b>
          </div>
        )
      }
    ];
    setColumns(updatedColumns);
  }, [
    selectedMonth,
    yearEndSimulation,
    monthEndSimulation,
    selectedData,
    lang
  ]);

  const tListIsFull = dataReformat.length > 0;

  return (
    <Card className="px-0 my-3">
      <Card.Body className="p-0">
        {!isMobile && tListIsFull && (
          <AdvanceTableWrapper
            columns={columns}
            data={dataReformat}
            pagination
            perPage={10}
          >
            <AdvanceTable
              table
              headerClassName="bg-200 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: false,
                striped: false,
                className: 'mb-0 overflow-hidden text-dark'
              }}
            />
            {dataReformat.length > 10 ? (
              <div className="mt-3">
                <AdvanceTablePagination table />
              </div>
            ) : (
              <></>
            )}
          </AdvanceTableWrapper>
        )}

        {isMobile && selectedYear && selectedData && (
          <Row>
            <Col>
              <Table striped bordered hover responsive>
                <tbody>
                  <tr>
                    <td className="text-dark">
                      <strong>Totale</strong>
                    </td>
                    <td className={`text-end fw-bold`}>
                      <span
                        className={`text-${getCellClass(
                          getTotalForYear(data, selectedYear)
                        )}`}
                      >
                        {getTotalForYear(data, selectedYear)}
                      </span>
                      <span className="text-dark"> %</span>
                    </td>
                  </tr>
                  {selectedData.map(
                    (value, i) =>
                      value !== 0 && (
                        <tr key={i}>
                          <td>{months[i]}</td>
                          <td className="text-end">
                            <span className={`text-${getCellClass(value)}`}>
                              {value}
                            </span>
                            <span className="text-dark"> %</span>
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        )}

        {(!tListIsFull || (isMobile && (!selectedYear || !selectedData))) && (
          <Row className="m-4">
            <h5 className="text-center">{textLang.noData[lang]}</h5>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

BacktestTable.propTypes = {
  data: PropTypes.object.isRequired,
  getTotalForYear: PropTypes.func.isRequired,
  selectedYear: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  endSimulation: PropTypes.object.isRequired,
  selectedMonth: PropTypes.number
};

export default BacktestTable;
